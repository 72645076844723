import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	head: {
		background: theme.workwaveBlue,
		color: theme.white,
		fontSize: '1.2rem',
	},
	body: {
		fontSize: '1.2rem',
	},
}));

function createData(annual, spend, rebate) {
	return { annual, spend, rebate };
}

const rows = [
	createData('$0', '$50,000', '1.0%'),
	createData('$50,001', '$75,000', '1.5%'),
	createData('$75,000', '$100,00', '2.0%'),
	createData('$100,001', '$150,000', '2.5%'),
	createData('$150,001', '$200,000', '3.0%'),
	createData('200,001', '$250,000', '4.0%'),
	createData('$250,001', 'More', '5.0%'),
];

export const TargetSpecialTable = () => {
	const classes = useStyles();
	return (
		<TableContainer component={Paper}>
			<Table aria-label='simple table'>
				<TableHead className={classes.head}>
					<TableRow>
						<TableCell
							align='center'
							style={{
								color: 'white',
								fontWeight: 600,
								fontSize: '1.2rem',
							}}>
							Annual
						</TableCell>
						<TableCell
							style={{
								color: 'white',
								fontWeight: 600,
								fontSize: '1.2rem',
							}}
							align='center'>
							Spend
						</TableCell>
						<TableCell
							style={{ color: 'white', fontWeight: 600, fontSize: '1.2rem' }}
							align='center'>
							Rebate %
						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{rows.map((row) => (
						<TableRow key={row.annual}>
							<TableCell
								component='th'
								scope='row'
								align='center'
								style={{
									fontWeight: 600,
									fontSize: '1.2rem',
									borderRight: '1px solid #E0E0E0',
								}}>
								{row.annual}
							</TableCell>
							<TableCell
								align='center'
								style={{
									fontWeight: 600,
									borderRight: '1px solid #E0E0E0',
									fontSize: '1.2rem',
								}}>
								{row.spend}
							</TableCell>
							<TableCell
								align='center'
								style={{ fontWeight: 600, fontSize: '1.2rem' }}>
								{row.rebate}
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
};
