import React from 'react';

import { Grid, Typography, Button } from '@material-ui/core';

export const ExternalFormLink = ({ formLink }) => {
	const { title, cta, link } = formLink;
	return (
		<Grid
			container
			direction='column'
			justifyContent='center'
			alignItems='center'
			id='form'
			style={{ padding: '8rem 0' }}>
			<Typography
				variant='h3'
				color='primary'
				style={{ paddingBottom: '2rem', fontWeight: 600, fontSize: '2.1rem' }}>
				{title}
			</Typography>
			<a href={link} target='_blank'>
				<Button
					variant='contained'
					color='primary'
					size='large'
					style={{ padding: '1rem 2rem', fontSize: '1.4rem' }}>
					{cta}
				</Button>
			</a>
		</Grid>
	);
};
