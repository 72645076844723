import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';

import { Container, Grid } from '@material-ui/core';

export const SpecialCta = ({ specialCta }) => {
  return (
    <Container style={{ padding: '4rem 0' }}>
      <Grid container justifyContent='center' alignItems='center'>
        <GatsbyImage
          style={{
            borderRadius: '20px',
            overflow: 'hidden',
            backgroundSize: 'cover',
          }}
          image={specialCta?.asset?.gatsbyImageData}
          quality={100}
        />
      </Grid>
    </Container>
  );
};
