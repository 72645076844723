import React from 'react';
import PortableText from 'react-portable-text';

import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

import { TargetSpecialTable } from './TargetSpecialTable';

const useStyles = makeStyles((theme) => ({
	block: {
		'& > h2:nth-child(1)': {
			color: theme.workwaveBlue,
			marginTop: 0,
		},
		'& p': {
			fontSize: 16,
		},
		'& strong': {
			color: theme.workwaveBlue,
		},
		'& em': {
			color: '#8394A2',
		},
		'& a': {
			color: theme.workwaveBlue,
		},
	},
}));

export const TargetSpecialSection = ({ body }) => {
	const classes = useStyles();

	return (
		body && (
			<Grid container spacing={4} style={{ marginTop: '4rem' }}>
				<Grid item xs={12} md={6}>
					<TargetSpecialTable />
				</Grid>
				<Grid item xs={12} md={6}>
					{body.targetSpecialSection.map((content) => (
						<PortableText content={content} className={classes.block} />
					))}
				</Grid>
			</Grid>
		)
	);
};
